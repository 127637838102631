import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDButton from "../../../../components/MDButton";
import Slide from "@mui/material/Slide";
import MDBox from "../../../../components/MDBox";
import CloseIcon from "@mui/icons-material/Close";
import { Card, CardContent, CircularProgress, Dialog, Menu, MenuItem } from "@mui/material";
import MDTypography from "../../../../components/MDTypography";
import TextField from "@mui/material/TextField";
import validator from "validator";
import { useMaterialUIController } from "../../../../context";

function NewTaskModal(props) {
  const [controller] = useMaterialUIController();
  const { openAddTaskModal, ToogleAddTaskModal, newTask, setNewTask, handleAddTask } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const frequencies = ["every 2mins", "every 5mins", "every 30mins", "every day"];
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = useState(false); // Keep the loading state
  const { darkMode } = controller;

  return (
    <Dialog
      open={openAddTaskModal}
      aria-labelledby="customized-dialog-title"
      fullWidth
      maxWidth={"sm"}
      PaperProps={{
        style: {
          backgroundColor: darkMode ? "#2a2b2f" : "#EBEFF4",
          boxShadow: "none",
        },
      }}
    >
      <Slide direction="up" in={openAddTaskModal} timeout={500}>
        <MDBox
          position="relative"
          display="flex"
          flexDirection="column"
          borderRadius="xl"
          shadow="xl"
          bgColor={darkMode ? "dark" : "white"}
        >
          <MDBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
            <MDBox display="flex" alignItems="center">
              <Card display="flex" alignItems="center" sx={{ mr: 1, p: 1 }}>
                <Icon color={"info"} fontSize={"large"}>
                  settings
                </Icon>
              </Card>
              <MDBox display="flex" flexDirection="column" sx={{ pt: 1 }}>
                <MDTypography variant="button" color="text">
                  {"Job Settings for"}
                </MDTypography>
                <MDTypography variant="body1" fontWeight="bold">
                  {newTask ? newTask.title : ""}
                </MDTypography>
              </MDBox>
            </MDBox>
            <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={ToogleAddTaskModal} />
          </MDBox>

          <Divider sx={{ my: 0 }} />
          {/* Move content from the first tab directly here */}
          <Card sx={{ mx: 2 }} borderRadius={"xl"}>
            <CardContent>
              <MDTypography>Basic Options</MDTypography>
              <Divider />
              <MDBox display={"flex"} flexDirection="column">
                <MDBox display={"flex"} flexDirection="column" pb={0.5}>
                  <MDTypography variant={"button"}>Title</MDTypography>
                  <TextField
                    fullWidth
                    value={newTask ? newTask.title : ""}
                    onChange={(e) => {
                      setNewTask((prevStave) => ({
                        ...prevStave,
                        title: e.target.value,
                      }));
                    }}
                  />
                </MDBox>
                <MDBox display={"flex"} flexDirection="column" pb={0.5}>
                  <MDTypography variant={"button"}>URL</MDTypography>
                  <TextField
                    fullWidth
                    value={newTask ? newTask.url : ""}
                    error={errorMessage !== "" ? true : false}
                    helperText={errorMessage}
                    onChange={(e) => {
                      setNewTask((prevStave) => ({
                        ...prevStave,
                        url: e.target.value,
                      }));
                    }}
                  />
                </MDBox>
                <MDBox
                  sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
                  pt={1.5}
                >
                  <MDTypography variant={"button"} sx={{ mr: 1 }}>
                    Frequency:
                  </MDTypography>
                  <MDButton
                    variant="outlined"
                    onClick={(e) => {
                      setAnchorEl(e.currentTarget);
                    }}
                    color={darkMode ? "light" : "dark"}
                    sx={{ width: "fit-content" }}
                  >
                    {newTask ? newTask.frequency : ""} <Icon>expand_more</Icon>
                  </MDButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => {
                      setAnchorEl(null);
                    }}
                  >
                    {frequencies.map((val, key) => (
                      <MenuItem
                        key={key}
                        onClick={() => {
                          setNewTask((prevStave) => ({
                            ...prevStave,
                            frequency: val,
                          }));
                          setAnchorEl(null);
                        }}
                      >
                        {val}
                      </MenuItem>
                    ))}
                  </Menu>
                </MDBox>
              </MDBox>
            </CardContent>
          </Card>
          <Divider sx={{ my: 0 }} />
          <MDBox display="flex" justifyContent="space-between" p={1.5}>
            <MDButton variant="gradient" color="light" onClick={ToogleAddTaskModal}>
              close
            </MDButton>
            <MDButton
              variant="gradient"
              color="info"
              onClick={async () => {
                if (validator.isURL(newTask.url)) {
                  try {
                    setLoading(true); // Set loading to true
                    await handleAddTask(newTask);
                    setErrorMessage("");
                  } catch (error) {
                    setErrorMessage(error.message || error.toString());
                  } finally {
                    setLoading(false); // Set loading to false after the process
                  }
                } else {
                  setErrorMessage("Is Not Valid URL");
                }
                if (newTask.url.length === 0) {
                  setErrorMessage("Is Not Empty URL");
                }
              }}
              disabled={loading} // Disable the button while loading
            >
              {loading ? (
                <>
                  <CircularProgress size={20} sx={{ mr: 1 }} color="primary" />
                  Saving...
                </>
              ) : (
                "Save Changes"
              )}
            </MDButton>
          </MDBox>
        </MDBox>
      </Slide>
    </Dialog>
  );
}

export default NewTaskModal;
