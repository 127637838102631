import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDButton from "../../../../components/MDButton";
import Slide from "@mui/material/Slide";
import MDBox from "../../../../components/MDBox";
import CloseIcon from "@mui/icons-material/Close";
import { Card, CardContent, CircularProgress, Dialog, Menu, MenuItem } from "@mui/material";
import MDTypography from "../../../../components/MDTypography";
import TextField from "@mui/material/TextField";
import validator from "validator";
import { useMaterialUIController } from "../../../../context";

function EditTaskModal(props) {
  const { openEditModal, ToogleEditModal, handleUpdateTask, editTask, setEditTask } = props;
  const [controller] = useMaterialUIController();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const frequencies = ["every 2mins", "every 5mins", "every 30mins", "every day"];
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  const { darkMode } = controller;

  function convertTextToLinks(text) {
    const urlRegex = /(^|\s)(https?:\/\/[^\s]+|www\.[^\s]+|[\w-]+\.com[^\s]*)/g;
    return text.replace(urlRegex, (match, space, url) => {
      if (url.startsWith("www.")) {
        url = `https://${url}`;
      } else if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = `https://www.${url}`;
      }
      return url;
    });
  }

  return (
    <Dialog
      open={openEditModal}
      aria-labelledby="customized-dialog-title"
      fullWidth
      maxWidth={"sm"}
      PaperProps={{
        style: {
          backgroundColor: darkMode ? "#2a2b2f" : "#EBEFF4",
          boxShadow: "none",
        },
      }}
    >
      <Slide direction="up" in={openEditModal} timeout={500}>
        <MDBox
          position="relative"
          display="flex"
          flexDirection="column"
          borderRadius="xl"
          shadow="xl"
          bgColor={darkMode ? "dark" : "white"}
        >
          <MDBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
            <MDBox display="flex" alignItems="center">
              <Card display="flex" alignItems="center" sx={{ mr: 1, p: 1 }}>
                <Icon color={"info"} fontSize={"large"}>
                  settings
                </Icon>
              </Card>
              <MDBox display="flex" flexDirection="column" sx={{ pt: 1 }}>
                <MDTypography variant="button" color="text">
                  {"Job Settings for"}
                </MDTypography>
                <MDTypography variant="body1" fontWeight="bold">
                  {editTask?.title}
                </MDTypography>
              </MDBox>
            </MDBox>
            <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={ToogleEditModal} />
          </MDBox>

          <Divider sx={{ my: 0 }} />
          {/* Move content directly here, similar to NewTaskModal */}
          <Card sx={{ mx: 2 }} borderRadius={"xl"}>
            <CardContent>
              <MDTypography>Basic Options</MDTypography>
              <Divider />
              <MDBox display={"flex"} flexDirection="column">
                <MDBox display={"flex"} flexDirection="column" pb={0.5}>
                  <MDTypography variant={"button"}>Title</MDTypography>
                  <TextField
                    fullWidth
                    value={editTask?.title}
                    onChange={(e) => {
                      setEditTask((prevStave) => ({
                        ...prevStave,
                        title: e.target.value,
                      }));
                    }}
                  />
                </MDBox>
                <MDBox display={"flex"} flexDirection="column" pb={0.5}>
                  <MDTypography color={"text"} variant={"button"}>
                    URL
                  </MDTypography>
                  <TextField
                    fullWidth
                    value={editTask?.url}
                    error={errorMessage !== "" ? true : false}
                    helperText={errorMessage}
                    onChange={(e) => {
                      setEditTask((prevStave) => ({
                        ...prevStave,
                        url: convertTextToLinks(e.target.value),
                      }));
                    }}
                  />
                </MDBox>
                <MDBox
                  sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
                  pt={1.5}
                >
                  <MDTypography variant={"button"} sx={{ mr: 1 }}>
                    Frequency:
                  </MDTypography>
                  <MDButton
                    variant="outlined"
                    onClick={(e) => {
                      setAnchorEl(e.currentTarget);
                    }}
                    color={darkMode ? "light" : "dark"}
                    sx={{ width: "fit-content" }}
                  >
                    {editTask?.frequency} <Icon>expand_more</Icon>
                  </MDButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => {
                      setAnchorEl(null);
                    }}
                  >
                    {frequencies.map((val, key) => (
                      <MenuItem
                        key={key}
                        onClick={() => {
                          setEditTask((prevStave) => ({
                            ...prevStave,
                            frequency: val,
                          }));
                          setAnchorEl(null);
                        }}
                      >
                        {val}
                      </MenuItem>
                    ))}
                  </Menu>
                </MDBox>
              </MDBox>
            </CardContent>
          </Card>

          <Divider sx={{ my: 0 }} />
          <MDBox display="flex" justifyContent="space-between" p={1.5}>
            <MDButton variant="gradient" color="light" onClick={ToogleEditModal}>
              close
            </MDButton>
            <MDButton
              variant="gradient"
              color="info"
              onClick={async () => {
                if (validator.isURL(editTask?.url)) {
                  setLoading(true); // Set loading to true before saving
                  try {
                    await handleUpdateTask(editTask);
                    setErrorMessage("");
                  } catch (error) {
                    console.error(error);
                  } finally {
                    setLoading(false); // Set loading to false after saving
                  }
                } else {
                  setErrorMessage("Is Not Valid URL");
                }
                if (editTask?.url.length === 0) {
                  setErrorMessage("Is Not Empty URL");
                }
              }}
              disabled={loading} // Disable button while loading
            >
              {loading ? (
                <>
                  <CircularProgress size={20} sx={{ mr: 1 }} color="primary" />
                  Saving...
                </>
              ) : (
                "Save Changes"
              )}
            </MDButton>
          </MDBox>
        </MDBox>
      </Slide>
    </Dialog>
  );
}

export default EditTaskModal;
